<!-- perActiveImg  -->
<template>
	<div class="ac_calendar_aclist_item_content" @click="tipShow(item)">
		<div class="aclist_item_img">
			<img v-lazy="item.pic" :key="item.pic" />
			<!-- <div class="ac_calendar_start_hour">{{
				item.mainevent ? item.mainevent : formatTime(item.start_time)
			}}</div>
			<div
				class="ac_calendar_line"
				:class="{ ac_calendar_line_noStart: item.startStatus == 0 }"
				v-if="item.startStatus == 0"
			>
				<div> </div>
				活动尚未开始
			</div>
			<div
				class="ac_calendar_line"
				:class="{ ac_calendar_line_end: item.startStatus == 2 }"
				v-if="item.startStatus == 2"
			>
				<div> </div>
				活动已结束
			</div>
			<div
				class="ac_calendar_line"
				:class="{
					ac_calendar_line_progress: item.startStatus == 1,
				}"
				v-if="item.startStatus == 1"
			>
				<div></div>
				活动正在进行中
			</div> -->
			<div class="content_info_part ac_calendar_aclist_item_info">
				<img
					class="me_flag"
					v-if="item.is_top"
					src="https://res.metaera.hk/resources/assets/images/active/me_flag.svg"
				/>
				<div class="content_position">
					<div class="ac_main_event">{{ item.mainevent }}</div>
					<div class="ac_calendar_aclist_item_title">{{ item.title }}</div>
					<div class="ac_calendar_aclist_item_date">
						<img
							src="https://res.metaera.hk/resources/assets/images/active/7.png"
						/>
						<span
							>{{ formatDate(item.start_time) }} {{ item.timeZone }} -
							{{ formatDate(item.end_time) }}
							{{ item.timeZone }}</span
						>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="ac_calendar_aclist_item_info">
									<div class="ac_calendar_aclist_item_area">
										<img
											src="https://res.metaera.hk/resources/assets/images/active/6.png"
										/>
										<span>{{ item.address ? item.address : "暂无地址" }}</span>
									</div>
								</div> -->
		<!-- <div
									class="ac_calendar_aclist_item_people"
									v-if="item.sideevent == null || item.sideevent == ''"
								>
									<div class="ac_calendar_aclist_item_organizer">
										<span>ORGANIZER：</span>
										<img :src="getHostArray(item).pic" />
										<span>{{ getHostArray(item).name }}</span>
									</div>
								</div> -->

		<!-- <div class="ac_calendar_aclist_item_side" v-else>
									<div class="ac_calendar_aclist_item_side_left">
										# {{ item.sideevent_title }}</div
									>
									<div class="ac_calendar_aclist_item_side_right"
										>SideEvent</div
									>
								</div> -->
	</div>
</template>
<script>
export default {
	name: "perActiveImg",
	props: ["item"],
	data() {
		return {};
	},
	methods: {
		tipShow(item) {
			this.item = item;
			// this.showTips = true;
			// 生成目标路由的 URL
			const routePath = this.$router.resolve({
				name: "/ac_detail",
				params: { id: this.item.id },
			}).href;
			// 在新标签页中打开目标路由
			window.open(routePath, "_blank");
		},
		formatTime(timestamp) {
			const date = new Date(timestamp);
			let hours = date.getHours();
			const minutes = date.getMinutes();
			const ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			const strMinutes = minutes < 10 ? "0" + minutes : minutes;
			return hours + ":" + strMinutes + " " + ampm;
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				hour12: false,
			});
		},
	},
};
</script>
<style scoped>
.ac_calendar_aclist_item_content {
	position: relative;
	z-index: 1;
}
.aclist_item_img {
	position: relative;

	border-radius: 10px;
	/* padding: 12px; */
}

.aclist_item_img img {
	width: 100%;
	border-radius: 10px;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}
.ac_calendar_start_hour {
	top: 10px; /* 距离顶部的距离 */
	left: 10px; /* 距离左边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	display: none;
}
.ac_calendar_line {
	top: 10px; /* 距离顶部的距离 */
	right: 10px; /* 距离右边的距离 */
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
	color: white; /* 字体颜色 */
	border-radius: 7px;
	font-family: PingFang SC;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.ac_calendar_line_noStart {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #0256ff;
}

.ac_calendar_line_end {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: gray;
}

.ac_calendar_line_noStart div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
}

.ac_calendar_line_progress {
	background-color: rgba(255, 255, 255, 0.9); /* 半透明背景 */
	color: #08bf66;
}
.ac_calendar_line_progress div {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	margin-right: 10px;
	background: #08bf66;
}
.ac_calendar_aclist_item_info img {
	width: clamp(8px, calc(100vw * 8 / 375), 12px);
	height: clamp(8px, calc(100vw * 8 / 375), 12px);
	margin-right: 6px;
}
.ac_calendar_aclist_item_info .me_flag {
	width: 33px;
	height: auto;
	border-radius: 0;
	position: absolute;
	left: 20px;
	aspect-ratio: auto;
}
.content_info_part {
	margin: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(0deg, #000000 0%, rgba(51, 51, 51, 0) 99%);
}
.content_position {
	position: absolute;
	bottom: 20px;
	left: 20px;
	right: 20px;
}
.ac_main_event {
	font-size: clamp(8px, calc(100vw * 8 / 375), 12px);
	line-height: 8px;
	color: #fff;
}
.ac_calendar_aclist_item_title {
	font-size: clamp(14px, calc(100vw * 14 / 375), 16px);
	font-weight: 400;
	line-height: 20px;
	text-transform: uppercase;
	letter-spacing: 0em;

	/* 纯白 */
	color: #fff;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	margin-right: 10px;
}
.ac_calendar_aclist_item_date,
.ac_calendar_aclist_item_date span {
	color: #ffffff;
	font-size: clamp(10px, calc(100vw * 10 / 375), 12px);
	line-height: 1.2;
}
</style>
